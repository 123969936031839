<script lang="ts" setup>
defineProps<{
  background?: string;
}>();
const items = [
  {
    name: "Angular",
    link: "https://angular.io/",
    image: "/images/Angular_2-1.svg",
  },
  {
    name: "Apache",
    link: "https://apache.org",
    image: "/images/Apache_2-1.svg",
  },
  {
    name: "Docker",
    link: "https://www.docker.com",
    image: "/images/Docker_2-1.svg",
  },
  {
    name: "Flutter",
    link: "https://flutter.dev/",
    image: "/images/Flutter_2-1.svg",
  },
  {
    name: "GitLab",
    link: "https://about.gitlab.com/",
    image: "/images/GitLab_2-1.svg",
  },
  {
    name: "JHipster",
    link: "https://www.jhipster.tech/",
    image: "/images/JHipster_2-1.svg",
  },
  {
    name: "Kubernetes",
    link: "https://kubernetes.io/",
    image: "/images/Kubernetes_2-1.svg",
  },
  {
    name: "Node.js",
    link: "https://nodejs.org/en/",
    image: "/images/NodeJS_2-1.svg",
  },
  {
    name: "Portainer",
    link: "https://www.portainer.io/",
    image: "/images/Portainer_2-1.svg",
  },
  {
    name: "React",
    link: "https://react.dev/",
    image: "/images/React_2-1.svg",
  },
  {
    name: "Sentry",
    link: "https://sentry.io/",
    image: "/images/Sentry_2-1.svg",
  },
  {
    name: "Spring Framework",
    link: "https://spring.io/projects/spring-framework",
    image: "/images/Spring_2-1.svg",
  },
  {
    name: "Vue.js",
    link: "https://vuejs.org/",
    image: "/images/VueJS_2-1.svg",
  },
  {
    name: "Zabbix",
    link: "https://www.zabbix.com/",
    image: "/images/Zabbix_2-1.svg",
  },
];
</script>

<template>
  <Icons :items="items" :background="background" />
</template>
